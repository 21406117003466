<template>
  <NavBar></NavBar>

  <div class="container mt-5">
    <h1 class="">Mentions légales</h1>
    <h2 class="">Comment nous contacter ?</h2>
    <p><span class="text-uppercase">Addresse Postale</span><br>
      FUJI TOP - SAYURI <br>
      115 AV ARISTIDE BRIAND<br>
      92120 MONTROUGE<br><br>

      <span class="text-uppercase">Notre service client:</span><br>
      Vous pouvez joindre notre service client au 01 42 53 48 19 (numéro non surtaxé) aux heures d'ouverture du restaurant.<br></p>

    <h2 class="bap-title-darker">La Société</h2>
    <p>FUJI TOP SARL <br>
      au capital de 8 000 € <br>
      Inscrite au RCS de Nanterre B 802 798 405.<br>
      Directeur de la publication: MME Xiaochun PENG.<br>
      N° de TVA Intracommunautaire: FR75802798405<br></p>

    <h2 class="bap-title-darker">Hébergement</h2>
    <p>Le site est hébergé chez Amazon Web Services EMEA SARL, 38 Avenue John F. Kennedy, L-1855, Luxembourg.</p>
  </div>
  <DefaultFooter></DefaultFooter>
</template>

<script>
import NavBar from "@/components/NavBar";
import DefaultFooter from "@/components/DefaultFooter";
import {useHead} from "@vueuse/head";
import Consts from "@/consts";

export default {
  name: "LegalMentions",
  components: {DefaultFooter, NavBar},
  setup() {
    useHead({
      // Can be static or computed
      title: "Sayuri Montrouge Site Officiel - Mentions légales",
      meta: [
        {
          name: "description",
          content: Consts.STANDARD_DESCRIPTION,
        },
      ],
    })
  },
}
</script>

<style scoped>

</style>
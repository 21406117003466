<template>
  <div>
    <div v-for="item in items" :key="item.sku" class="mb-2">
      <div class="row">
        <div  class="no-gutters col-3" v-if="item.images !== null && item.images.length >=1 ">
          <img class=" img-fluid" :src="item.images[0].url" :alt="item.title" >
        </div>
        <div class="col">
          <h4>{{ item.title }}</h4>
          <p>Prix Unitaire: {{ item.unitPriceVATInc.display }}</p>
          <h6>{{ item.totalPriceVATInc.display }}</h6>
          <div v-if="updatable === true">
            Quantité:
            <button type="button" class="btn btn-primary" @click="updateItem(item, -1)" :disabled="alreadyInUpdate[item.id]">
              -
            </button>
            {{item.quantity}}
            <button type="button" class="btn btn-primary" @click="updateItem(item, 1)" :disabled="alreadyInUpdate[item.id]">
              +
            </button>
          </div>
          <div v-else>
            Quantity: {{item.quantity}}
          </div>
          <a href="#" v-if="updatable" class="mr-2 mt-2 link-danger" @click="updateItem(item,-item.quantity)">Supprimer</a>
        </div>
      </div>
      <hr>
    </div>
  </div>
</template>

<script>
import AuthHelper from "@/authHelper";
import Webservice from "@/webservice";
import _ from "lodash";

export default {
  name: "CartView",
  props: {
    items: Object,
    updatable: Boolean
  },
  data() {
    return {
      itemsCloned: null,
      showSpinner: false,
      alreadyInUpdate: {},
    }
  },
  created() {
    this.cloneItems()
  },
  methods: {
    cloneItems() {
      this.itemsCloned = _.cloneDeep(this.items)

    },
    updateItem(item, delta) {
      if (this.alreadyInUpdate[item.id]) {
        return
      }
      this.alreadyInUpdate[item.id] = true
      this.loading = true
      AuthHelper.getAnonUserOrCreateOneIfExpired().then((user) => {
        Webservice.addToCart(item.id,delta,user.idToken).then(() => {

          Webservice.tracking({
            action:{
              referrer:document.referrer,
              lang: navigator.language || navigator.userLanguage,
              name:"ADD_TO_CART",
              utm: {
                source: this.$route.query.utm_source,
                medium: this.$route.query.utm_medium,
                campaign: this.$route.query.utm_campaign,
                term: this.$route.query.utm_term,
                content: this.$route.query.utm_content,
              }
            }
          }, user.idToken)
          this.$emit('cart-updated')
          this.emitter.emit("add-to-cart");
          this.cloneItems()
          this.alreadyInUpdate[item.id] = false
          this.loading = false
        }).catch((err) => {
          // when we have 403 we force anon user recreation
          if (err.response.status === 403){
            // refresh the user
            AuthHelper.createAnonUser().then(()=>{
              // relaunch treatment
              this.updateItem(item,delta)
            })
          }
          console.log("err while update cart" + err)
          this.alreadyInUpdate[item.id] = false
          this.loading = false
        })
      }).catch((err) => {
        console.log("err while get user")
        console.log(err)
        this.alreadyInUpdate = false
        this.loading = false
      })
    },
  }
}
</script>

<style scoped>

</style>
import { createRouter, createWebHistory } from 'vue-router'
import Consts from '../consts'
import RestoView from "@/views/RestoView";
import FunnelLivraisonStepProductSelection from "@/views/FunnelLivraisonStepProductSelection";
import FunnelAEmporterStep1 from "@/views/FunnelAEmporterStep1";
import FunnelLivraisonStepRecap from "@/views/FunnelLivraisonStepRecap";
import Webservice from "@/webservice";
import CreditsView from "@/views/CreditsView";
import CGVView from "@/views/CGVView";
import LegalMentions from "@/views/LegalMentions";

const routes = [
  {
    path: '/',
    name: Consts.ROUTE_NAMES.FUNNEL_MODE,
    component: FunnelLivraisonStepProductSelection
  },
  {
    path: '/menu',
    name: Consts.ROUTE_NAMES.FUNNEL_LIVRAISON_STEP_MENU,
    component: FunnelLivraisonStepProductSelection
  },
  {
    path: '/livraison/recapitulatif',
    name: Consts.ROUTE_NAMES.FUNNEL_LIVRAISON_STEP_RECAP,
    component: FunnelLivraisonStepRecap
  },
  {
    path: '/livraison/addresse',
    name: Consts.ROUTE_NAMES.FUNNEL_LIVRAISON_STEP_ADDRESS,
    component: () => import('../views/FunnelLivraisonStepAddress')
  },
  {
    path: '/a-emporter',
    name: Consts.ROUTE_NAMES.FUNNEL_A_EMPORTER_STEP_1,
    component: FunnelAEmporterStep1
  },
  {
    path: '/restaurant',
    name: Consts.ROUTE_NAMES.RESTO,
    component: RestoView
  },
  {
    path: '/credits',
    name: Consts.ROUTE_NAMES.CREDITS,
    component: CreditsView
  },
  {
    path: '/cgv',
    name: Consts.ROUTE_NAMES.CGV,
    component: CGVView
  },
  {
    path: '/mentions-legales',
    name: Consts.ROUTE_NAMES.LEGAL_MENTIONS,
    component: LegalMentions
  },
  {
    path: '/livraison/commande-validee',
    name: Consts.ROUTE_NAMES.ORDER_OK,
    component: () => import('../views/OrderOk')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.afterEach((to, from) => {

  Webservice.tracking({
    pageView:{
      referrer:document.referrer,
      lang: navigator.language || navigator.userLanguage,
      to:to.path,
      from:from.path,
      utm: {
        source: to.query.utm_source,
        medium: to.query.utm_medium,
        campaign: to.query.utm_campaign,
        term: to.query.utm_term,
        content: to.query.utm_content,
      }
    }
  })
})

export default router

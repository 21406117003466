<template>
  <!-- Alert block -->
  <div class="alert alert-danger" v-if="showErrorAlert" role="alert">
    {{ errorMsg }}
  </div>
  <div class="alert alert-success alert-dismissible fade show" v-if="showSuccessAlert" role="alert">
    <i class="bi bi-box2-heart"></i> {{ successMsg }}
  </div>
  <DefaultLoader v-if="loading"></DefaultLoader>
  <div v-else>
    <ul class="nav  nav-tabs">
      <li class="nav-item" v-for="(category, idx) in menu.categories" :key="idx">
        <a class="nav-link" :href="'#'+category.slug">{{ category.title }}</a>
      </li>
    </ul>


    <div v-for="(d, idx) in menu.homeData" :key="idx">
      <h4 :id="d.category.slug" class="mt-2">{{d.category.title}}</h4>
      <div class="row row-cols-1 row-cols-md-2 g-4">
        <div v-for="(product, idx) in d.products" :key="idx" class="col">
            <ProductCard :product="product" v-on:add-to-cart="onAddToCart"></ProductCard>
        </div>
      </div>
    </div>
  </div>




</template>

<script>
import WebService from "@/webservice";
import Consts from "@/consts";
import DefaultLoader from "@/components/DefaultLoader";
import ProductCard from "@/components/ProductCard";


export default {
  name: "ProductsHome",
  components: {ProductCard, DefaultLoader},
  data() {
    return {
      menu:null,
      // base
      routeNames: Consts.ROUTE_NAMES,
      loading: false,
      // for error alerts
      showErrorAlert: false,
      errorMsg: null,
      // for success
      showSuccessAlert: false,
      successMsg: ''
    }
  },
  props:{
    categoryId:String,
  },
  created() {
    this.fetchData()
  },
  methods: {
    onAddToCart(){
      this.emitter.emit("add-to-cart");
    },
    fetchData(){
      this.loading = true
      WebService.getProductsHome().then((res)=>{
        this.menu = res.data
        console.log(this.menu)
        this.loading = false
      }).catch((err)=> {
        console.error(err)
        this.errorMsg = "Une erreur est survenue, merci de réessayer dans quelques secondes"
        this.showErrorAlert= true
        this.loading = false
      })
    },

  }
}
</script>

<style scoped>
.container a {
  color: #000000;
  text-decoration: none;
}
</style>
<template>
  <div>
    <NavBar></NavBar>
    <div class="container">
      <ProductsHome></ProductsHome>
    </div>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar";
import ProductsHome from "@/components/ProductsHome";
export default {
  name: "FunnelAEmporterStep1",
  components: {ProductsHome, NavBar}
}
</script>

<style scoped>

</style>
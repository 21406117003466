<template>
  <div>
    <NavBar></NavBar>
    <div class="container mt-3">

      <div class="bg-light p-5 rounded mb-2">
        <h1>Sayuri Montrouge</h1>
        <h5>Site Officiel</h5>
        <br>
        <img src="https://cdn.sayuri-montrouge.fr/icons8-restaurant-48.png" class="mr-2" alt="resto"> Notre restaurant <strong>familial</strong> est attaché à la <strong>qualité</strong> et à la <strong>fraîcheur des produits</strong>. <br>
        <img src="https://cdn.sayuri-montrouge.fr/icons8-cook-skin-type-3-48.png" class="mr-2" alt="cook"> Nos plats sont pour la plupart <strong>fait maison</strong>.<br>
        <img src="https://cdn.sayuri-montrouge.fr/icons8-fish-food-48.png" class="mr-2" alt="nourriture"> Notre poisson arrive <strong>tous les matins de Rungis</strong> afin de vous assurer une qualité optimale.<br>
        <br>
        <img style="width: 350px;" :src="require('@/assets/paiement secure.jpeg')" alt="Your Image Description">
      </div>

      <div class="row pl-4">
        <div class="col">
          <div v-if="this.cart && this.cart.isRestaurantOpen">
            <img src="https://cdn.sayuri-montrouge.fr/green-dot.jpg" class="mr-2" alt="resto" style="height:15px"> Restaurant actuellement <strong>Ouvert</strong> !
            <br>
            Commandez sur notre site <span>&#128071;</span> ou par téléphone  <span>&#128073;</span> : <a @click="onPhoneClick" class="resto-phone-number" href="tel:+331-42-53-48-19"><button class="btn btn-primary mb-2 btn-lg"><i class="bi bi-telephone"></i> 01 42 53 48 19</button></a>
          </div>
          <div v-else>
            <img src="https://cdn.sayuri-montrouge.fr/red-dot.jpg" class="mr-3" alt="resto" style="height:15px"> Restaurant actuellement Fermé
            <br>
            <p v-if="cart">{{ cart.openingInfo }}</p>
          </div>
        </div>
      </div>
      <div class="alert alert-success mt-2" role="alert">
        <strong>Livraison Offerte</strong>: Montrouge, Paris 14, Châtillon, Malakoff, Arcueil, Gentilly!
      </div>

      <h1>Notre Menu</h1>
      <hr>


      <div class="row">
        <div class="col-12 col-md-7">
          <ProductsHome></ProductsHome>

        </div>
        <div class="d-none d-lg-block d-xl-block col-4 border" style="background-color: rgba(78,78,78,0.07)">
          <CartComponent
              button-text="Suivant"
              v-on:cart-click-button="onCartClickNext"
              :cart="this.cart"
          ></CartComponent>
        </div>
      </div>
    </div>
    <!-- For mobile only @see https://getbootstrap.com/docs/5.1/utilities/display/ -->
    <!-- hide on md and wider screens : d-md-none -->
    <nav class=" fixed-bottom  d-md-none">
        <div class="d-grid gap-2">
          <button
              class="btn btn-success btn-lg"
              data-bs-toggle="modal" data-bs-target="#modalCart"
              type="button">Voir le panier</button>
        </div>
    </nav>

    <!-- Modal full screen -->
    <div class="modal " tabindex="-1" id="modalCart">
      <div class="modal-dialog modal-fullscreen">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Votre Commande</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <DefaultFooter v-if="loading"></DefaultFooter>
            <CartComponentMobile v-else
                :cart="this.cart"
            ></CartComponentMobile>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fermer</button>
            <button type="button" class="btn btn-primary" @click="onCartClickNext(this.cart)">Suivant</button>
          </div>
        </div>
      </div>
    </div>
    <!-- hide on mobile: d-none d-mb-block -->
    <DefaultFooter class="d-none d-md-block"></DefaultFooter>
    <br>
    <br>
    <br>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar";
import ProductsHome from "@/components/ProductsHome";
import CartComponent from "@/components/CartComponent";
import DefaultFooter from "@/components/DefaultFooter";
import Consts from "@/consts";
import toastr from "toastr";
import CartComponentMobile from "@/components/CartComponentMobile";
import AuthHelper from "@/authHelper";
import Webservice from "@/webservice";
import { Modal} from "bootstrap"
import { useHead } from '@vueuse/head'

export default {
  name: "FunnelLivraisonStepProductSelection",
  components: {CartComponentMobile, DefaultFooter, CartComponent, ProductsHome, NavBar},
  setup() {
    useHead({
      // Can be static or computed
      title: "Sayuri Montrouge Site Officiel - Commande en ligne",
      meta: [
        {
          name: "description",
          content: Consts.STANDARD_DESCRIPTION,
        },
      ],
    })
  },
  data(){
    return {
      reloadCart: false,
      routeNames : Consts.ROUTE_NAMES,
      cart: null,
      loading:false,
    }
  },
  created() {
    this.fetchData()
    this.track()
  },
  mounted() {
    // catch event cart-update to launch an update of cart data
    this.emitter.on("add-to-cart", e => {
      console.log(e)
      this.fetchData()
    });
  },
  methods:{
    track(){
      if (this.$route.query.d && this.$route.query.d !== ""){
        Webservice.tracking({
          action:{
            referrer:document.referrer,
            lang: navigator.language || navigator.userLanguage,
            name:"VISIT_PROMO_"+this.$route.query.d,
            utm: {
              source: this.$route.query.utm_source,
              medium: this.$route.query.utm_medium,
              campaign: this.$route.query.utm_campaign,
              term: this.$route.query.utm_term,
              content: this.$route.query.utm_content,
            }
          }
        })
      }
    },
    onPhoneClick(){
        Webservice.tracking({
          action:{
            referrer:document.referrer,
            lang: navigator.language || navigator.userLanguage,
            name:"CLICK_PHONE_HOME",
            utm: {
              source: this.$route.query.utm_source,
              medium: this.$route.query.utm_medium,
              campaign: this.$route.query.utm_campaign,
              term: this.$route.query.utm_term,
              content: this.$route.query.utm_content,
            }
          }
        })
    },
    fetchData() {
      this.loading = true
      AuthHelper.getAnonUserOrCreateOneIfExpired().then((user)=> {
        console.log("get cart from FunnelLivraisonStepProductSelection")
        Webservice.getCart(user.idToken).then((res)=>{
          this.cart = res.data
          this.loading = false
        }).catch((err)=>{
          // when we have 403 we force anon user recreation
          if (err.response.status === 403){
            // refresh the user
            AuthHelper.createAnonUser().then(()=>{
              // relaunch treatment
              this.fetchData()
            }).catch((err)=>{
              this.loading = false
              console.error(err)
              toastr.error('Une erreur est survenue, merci de réessayer plus tard')
            })
          } else {
            this.loading=false
            console.error("impossible to load cart : "+err)
            toastr.error('Une erreur est survenue, merci de réessayer plus tard')
          }
        })
      }).catch((err)=>{
        this.loading = false
        console.error(err)
        toastr.error('Une erreur est survenue, merci de réessayer plus tard')
      })
    },
    onCartClickNext(cart){
      Webservice.getRestaurantInfo().then((res)=>{
        if (!cart.isDeliveryAmountOK){
          toastr.error('Livraison disponible à partir de 15€ de commande, merci d"ajouter des articles.')
          return
        }
        if (!res.data.isRestoOpen){
          toastr.error("Le restaurant n'est pas encore ouvert !")
          return
        }
        let myModalEl = document.getElementById('modalCart')
        let modal = Modal.getInstance(myModalEl)
        if (modal) {
          // ony for mobile
          modal.hide()
        }
        this.$router.push({name: Consts.ROUTE_NAMES.FUNNEL_LIVRAISON_STEP_ADDRESS})
      }).catch((err)=>{
        console.error(err)
        toastr.error('Une erreur est survenue, merci de réessayer plus tard')

      })

    }
  }
}
</script>

<style scoped>

</style>
import { createApp } from 'vue'
import App from './App.vue'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap"
import "toastr/build/toastr.css"
import router from './router'
import mitt from 'mitt';
import 'maz-ui/css/main.css'
import { createHead } from '@vueuse/head'

const emitter = mitt();
const app = createApp(App)
const head = createHead()
app.use(head)

app.config.globalProperties.emitter = emitter;

app.use(router).mount('#app')

<template>
  <div>
    <div class="container">
      <h5 class="mt-5 mb-5"><i class="bi bi-bag"></i> Votre Commande</h5>
      <DefaultLoader v-if="cart === null"></DefaultLoader>
      <div v-else>
        <div class="text-center mb-5 mt-5" v-if="cart.items === null || cart.items.length === 0">
          <p>Votre Panier est vide</p>
          <p>Jetez un coup d'oeil à notre menu ! </p>
          <!--
          <router-link  :to="{ name: routeNames.HOME}">
            <button type="button" class="btn btn-info">Menu</button>
          </router-link>-->
        </div>
        <div v-else>
          <div class="container m-1 pb-1" v-if="this.cart">
            <!--- ITEMS -->
            <CartView :items="this.cart.items" :updatable="true"></CartView>
          </div>
          <!--- END ITEMS -->
          <!--- Basket Footer -->
          <div class="container">
            <div class="mt-4 row">
              <div class="col">
                Sous-Total:
              </div>
              <div class="text-right col" v-if="this.cart">
                <strong>{{ cart.totalPriceVATInc.display }}</strong>
              </div>
            </div>
            <hr>
            <div class="mt-2 row mb-3">
              <div class="col">
                Livraison:
              </div>
              <div  class="text-right col">
                <strong style="color: green">Gratuite !</strong>
              </div>
            </div>

            <div class="mt-2 mb-2 row" v-if="this.cart && showShippingAddress">
              <div class="col">
                <strong>Addresse de livraison:</strong><br>
                {{ cart.shippingAddress.name }}<br>
                {{ cart.shippingAddress.line1 }}<br>
                {{ cart.shippingAddress.postalCode }} {{ cart.shippingAddress.locality }}<br>
                <i class="bi bi-telephone"></i> {{ cart.shippingAddress.phoneNumberParsed.formatNational }}
              </div>
            </div>
            <div class="d-grid gap-2">
              <button class="btn btn-success" type="button" :disabled="disableButton" @click="onClickButton">
                <span v-if="disableButton">
                  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  Chargement...
                </span>
                <span v-else>
                  {{ buttonText }}
                </span>
              </button>
            </div>
            <small><i class="bi bi-percent"></i> Un code promo ? Vous pourrez le saisir au moment du paiement !</small>
            <CartReassurance></CartReassurance>
          </div>
          <!--- End  Basket Footer -->
        </div>
        <div class="alert alert-danger mt-3" role="alert" v-if="showErrorAlert">
          {{ errorMessage }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import AuthHelper from "../authHelper";
import Webservice from "../webservice";
import CartView from "@/components/CartView";
import ScriptLoader from "@/scriptLoader";
import DefaultLoader from "@/components/DefaultLoader";
import Consts from "@/consts";
import toastr from "toastr";
import CartReassurance from "@/components/CartReassurance";

export default {
  name: "CartComponent",
  components: {CartReassurance, CartView, DefaultLoader},
  props: {
    cart: Object,
    buttonText: String,
    showShippingAddress: Boolean,
    triggerPaymentOnClick: Boolean,
    checkoutMode: String,
  },
  data() {
    return {
      disableButton: false,
      shippingCountryCode: "",
      showSpinnerStripe: false,
      errorMessage: "",
      showErrorAlert: false,
      emptyCart: false,
      routeNames: Consts.ROUTE_NAMES,
      currency: "EUR",
      tutorialId: process.env.VUE_APP_MAIN_TUTORIAL_ID,
    }
  },
  methods: {
    openCrisp() {
      window.$crisp.push(['do', 'chat:open'])
    },
    onClickButton() {
      this.$emit("cart-click-button", this.cart)
      if (this.triggerPaymentOnClick) {
        // check if we can proceed to payment
        if (!this.cart.isDeliveryAddressOK) {
          toastr.error('Livraison disponible seulement à Montrouge, Chatillon, Malakoff, Arcueil, Gentilly ou Paris 14. Navré de ne pouvoir vous satisfaire')
          return
        }
        this.triggerPayment()
      }
    },
    triggerPayment() {
      this.disableButton = true
      this.showSpinnerStripe = true
      AuthHelper.getAnonUserOrCreateOneIfExpired().then((user) => {
        ScriptLoader.loadStripe().then(() => {
          let stripe = window.Stripe(process.env.VUE_APP_STRIPE_PUBLIC_KEY);
          let checkoutData = {
            provider: "STRIPE",
            checkoutMode: this.checkoutMode,
          }
          Webservice.checkoutv2(user.idToken, checkoutData).then((res) => {
            Webservice.tracking({
              action: {
                referrer: document.referrer,
                lang: navigator.language || navigator.userLanguage,
                name: "LAUNCH_PAYMENT",
                utm: {
                  source: this.$route.query.utm_source,
                  medium: this.$route.query.utm_medium,
                  campaign: this.$route.query.utm_campaign,
                  term: this.$route.query.utm_term,
                  content: this.$route.query.utm_content,
                }
              }
            }, user.idToken)
            stripe.redirectToCheckout({sessionId: res.data.sessionId});
          }).catch((err) => {
            // when we have 403 we force anon user recreation
            if (err.response.status === 403) {
              // refresh the user
              AuthHelper.createAnonUser().then(() => {
                // relaunch treatment
                this.triggerPayment()
              })
            }
            this.showSpinnerStripe = false
            this.disableButton = false
            console.error(err)
          })
        }).catch((err) => {
          this.showSpinnerStripe = false
          this.disableButton = false
          console.error(err)
        });
      }).catch((err) => {
        this.showSpinnerStripe = false
        this.disableButton = false
        console.error(err)
        this.makeToast("Error", "An unexpected error has occurred, please try again later", "danger")
      })
    },
  },

}
</script>

<style scoped>

</style>
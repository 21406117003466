
import Webservice from "../webservice";
const jose = require('jose')


function signOut(){
    return new Promise(function (resolve){
        localStorage.setItem('jwt_anon', "");
        resolve("signedOut")
    })
}

function createAnonUser(){
    return new Promise(function (resolve,reject) {
        Webservice.createAnonymousUser().then((res) => {
            // save anon user
            localStorage.setItem('jwt_anon', res.data.jwt);
            resolve({
                anonymous: true,
                idToken: res.data.jwt
            })
        }).catch((err) => {
            reject("impossible to create anonymous user:" + err)
        })
    })
}

function getOrCreateAnonUser(){
    return new Promise(function (resolve,reject){
        getAnonUserOrCreateOneIfExpired().then((user)=>{
            resolve(user)
        }).catch(()=>{
            // no anon user create one
            createAnonUser().then((user)=>{
                resolve(user)
            }).catch((err)=>{
              reject(err)
            })
        })
    })
}

function getAnonUserOrCreateOneIfExpired(){
    return new Promise(function (resolve,reject){
        let jwtStorage = localStorage.getItem('jwt_anon');
        if (!jwtStorage || jwtStorage === ""){
            createAnonUser().then((newUser) => {
                resolve(newUser)
            }).catch((err)=>{
                reject("err while create anon "+err)
            })
        } else {
            if (isJWTExpired(jwtStorage)){
                // we have an anon user but JWT is expired
                createAnonUser().then((newUser) => {
                    resolve(newUser)
                }).catch((err)=>{
                    reject("err while create anon "+err)
                })
            } else {
                resolve({
                    anonymous: true,
                    idToken: jwtStorage
                })
            }
        }
    })
}


function isJWTExpired(token){
    if (token) {
        const expiry = jose.decodeJwt(token).exp;
        const now = new Date();
        return now.getTime() > expiry * 1000;
    }
    return false;
}

export default {
    signOut : signOut,
    getOrCreateAnonUser:getOrCreateAnonUser,
    isJWTExpired:isJWTExpired,
    createAnonUser:createAnonUser,
    getAnonUserOrCreateOneIfExpired:getAnonUserOrCreateOneIfExpired
}
<template>
    <div class="card mb-3" style="max-width: 540px;" >
          <img class="card-img-top" v-if="product.images !== null" :src="product.images[0].url" :alt="product.images[0].alt">
      <img v-if="product.isSSR" src="https://cdn.sayuri-montrouge.fr/soupe-salade-riz.jpg" class="img-fluid" alt="...">
      <img v-if="product.isSoSa" src="https://cdn.sayuri-montrouge.fr/soupe-salade.jpg" class="img-fluid" alt="...">

      <div class="card-body">
            <h5 class="card-title">{{ product.title }}</h5>
            <p class="card-text">{{ product.shortDescription }}</p>
            <img v-if="product.isFM" style="height: 30px" class="" src="https://cdn.sayuri-montrouge.fr/FaitMaison.jpg"  alt="Fait Maison">
            <p class="card-text mt-2 price-text">{{ product.totalPrice.display }}</p>
            <button type="button"  @click="onAddToCart()" class="btn btn-outline-primary">Ajouter au Panier</button>
          </div>
      </div>

</template>

<script>
import AuthHelper from "@/authHelper";
import Webservice from "@/webservice";
import toastr from "toastr";
export default {
  name: "ProductCard",
  props: {
    product:Object,

  },
  methods:{
    onAddToCart(){



      // get current user & create anonymous user if no firebase user
      AuthHelper.getAnonUserOrCreateOneIfExpired().then((user)=> {
        Webservice.addToCart(this.product.id,1, user.idToken).then(()=> {
          Webservice.tracking({
            action:{
              referrer:document.referrer,
              lang: navigator.language || navigator.userLanguage,
              name:"ADD_TO_CART",
              utm: {
                source: this.$route.query.utm_source,
                medium: this.$route.query.utm_medium,
                campaign: this.$route.query.utm_campaign,
                term: this.$route.query.utm_term,
                content: this.$route.query.utm_content,
              }
            }
          },user.idToken)
          //this.showSuccessAlert = true
          //this.successMsg = "Produit ajouté au panier !"
          toastr.success('Ajouté au panier !')
          this.$emit('add-to-cart', this.product)
        }).catch((err)=> {
          // when we have 403 we force anon user recreation
          if (err.response.status === 403){
            // refresh the user
            AuthHelper.createAnonUser().then(()=>{
              // relaunch treatment
              this.onAddToCart()
            })
          }
          console.error(err)
          this.showErrorAlert = true
          this.errorMsg = "Impossible d'ajouter au panier merci de réessayer plus tard"
        })
      }).catch((err)=>{
        // What to do ?
        console.error("error while retrieving the current user:"+err)
        this.makeToast("Error", "An unexpected error has occurred, please try again later", "danger")

      })
    },
  }
}
</script>

<style scoped>
.price-text {
  color: #2f2f34;
}
</style>
<template>
  <NavBar></NavBar>
  <div class="container">
    <h1>Credits</h1>
    <a target="_blank" href="https://icons8.com/icon/12881/fish-food">Fish Food</a> icon by <a target="_blank" href="https://icons8.com">Icons8</a> <br>
    <a target="_blank" href="https://icons8.com/icon/7BO4viMLn8Ss/cook-skin-type-3">Cook Skin Type 3</a> icon by <a target="_blank" href="https://icons8.com">Icons8</a> <br>
    <a target="_blank" href="https://icons8.com/icon/2BeIdZyy0fFG/restaurant">Restaurant</a> icon by <a target="_blank" href="https://icons8.com">Icons8</a>
  </div>
  <DefaultFooter></DefaultFooter>
</template>

<script>
import NavBar from "@/components/NavBar";
import DefaultFooter from "@/components/DefaultFooter";
import {useHead} from "@vueuse/head";
import Consts from "@/consts";
export default {
  name: "CreditsView",
  setup() {
    useHead({
      // Can be static or computed
      title: "Sayuri Montrouge Site Officiel - Crédits",
      meta: [
        {
          name: "description",
          content: Consts.STANDARD_DESCRIPTION,
        },
      ],
    })
  },
  components: {DefaultFooter , NavBar}
}
</script>

<style scoped>

</style>
<template>
  <NavBar></NavBar>
  <div class="container">
    <br>
    <CGV></CGV>
  </div>
  <DefaultFooter></DefaultFooter>
</template>

<script>
import NavBar from "@/components/NavBar";
import CGV from "@/components/CGV";
import DefaultFooter from "@/components/DefaultFooter";
import {useHead} from "@vueuse/head";
import Consts from "@/consts";

export default {
  name: "CGVView",
  setup() {
    useHead({
      // Can be static or computed
      title: "Sayuri Montrouge Site Officiel - CGV",
      meta: [
        {
          name: "description",
          content: Consts.STANDARD_DESCRIPTION,
        },
      ],
    })
  },
  components: {DefaultFooter, CGV, NavBar}
}
</script>

<style scoped>

</style>
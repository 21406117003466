<template>
  <nav class="navbar navbar-expand-lg navbar-light " style="background-color: #a0827c;">
    <div class="container-fluid">
      <router-link  :to="{ name: routeNames.FUNNEL_MODE}" class="nav-link" >

        <a class="navbar-brand" >Sayuri Montrouge <span style="font-size: 10px">Site Officiel</span></a>
      </router-link>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav">
          <li class="nav-item">
            <router-link  :to="{ name: routeNames.FUNNEL_LIVRAISON_STEP_MENU}" class="nav-link" >Menu</router-link>
          </li>
          <li class="nav-item">
            <router-link  :to="{ name: routeNames.RESTO}" class="nav-link" >Restaurant</router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import Consts from "@/consts";

export default {
  name: "NavBar",
  data() {
    return {
      routeNames: Consts.ROUTE_NAMES,
    }
  },
}
</script>

<style scoped>

</style>
<template>
  <div class="spinner-border" role="status">
    <span class="sr-only"></span>
  </div>
</template>


<script>

export default {
name: "DefaultLoader",
  components:{}
}
</script>

<style scoped>

</style>
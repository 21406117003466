

function loadCrisp() {
    return new Promise(function(resolve, reject){
        window.$crisp = [];
        window.CRISP_WEBSITE_ID = process.env.VUE_APP_CRISP_WEBSITE_ID;
        let src = "https://client.crisp.chat/l.js";
        let shouldAppend = false;
        let el = document.querySelector('script[src="' + src + '"]');
        if (!el) {
            el = document.createElement('script');
            el.type = 'text/javascript';
            el.async = true;
            el.src = src;
            shouldAppend = true;
        }
        else if (el.hasAttribute('data-loaded')) {
            resolve(el);
            return;
        }
        el.addEventListener('error', reject);
        el.addEventListener('abort', reject);
        el.addEventListener('load', function loadScriptHandler() {
            el.setAttribute('data-loaded', true);
            resolve(el);
        });
        if (shouldAppend) document.head.appendChild(el);
    })
}


function loadStripe() {
    return new Promise(function(resolve, reject){
        let src = "https://js.stripe.com/v3/";
        let shouldAppend = false;
        let el = document.querySelector('script[src="' + src + '"]');
        if (!el) {
            el = document.createElement('script');
            el.type = 'text/javascript';
            el.async = true;
            el.src = src;
            shouldAppend = true;
        }
        else if (el.hasAttribute('data-loaded')) {
            resolve(el);
            return;
        }
        el.addEventListener('error', reject);
        el.addEventListener('abort', reject);
        el.addEventListener('load', function loadScriptHandler() {
            el.setAttribute('data-loaded', true);
            resolve(el);
        });
        if (shouldAppend) document.head.appendChild(el);
    })
}

function loadStream() {
    return new Promise(function(resolve, reject){
        let src = "https://embed.videodelivery.net/embed/sdk.latest.js";
        let shouldAppend = false;
        let el = document.querySelector('script[src="' + src + '"]');
        if (!el) {
            el = document.createElement('script');
            el.type = 'text/javascript';
            el.async = true;
            el.src = src;
            shouldAppend = true;
        }
        else if (el.hasAttribute('data-loaded')) {
            resolve(el);
            return;
        }
        el.addEventListener('error', reject);
        el.addEventListener('abort', reject);
        el.addEventListener('load', function loadScriptHandler() {
            el.setAttribute('data-loaded', true);
            resolve(el);
        });
        if (shouldAppend) document.head.appendChild(el);
    })
}
function loadPaypal(currency) {
    return new Promise(function(resolve, reject){
        let src = "https://www.paypal.com/sdk/js?currency="+currency+"&locale=en_US&client-id="+process.env.VUE_APP_PAYPAL_CLIENT_ID;
        let shouldAppend = false;
        let el = document.querySelector('script[src="' + src + '"]');
        if (!el) {
            el = document.createElement('script');
            el.setAttribute('data-namespace', "paypal-"+currency);
            el.type = 'text/javascript';
            //el["data-order-id"] = orderId
            //el["data-page-type"] = "checkout"
            el.async = true;
            el.src = src;
            shouldAppend = true;
        }
        else if (el.hasAttribute('data-loaded')) {
            resolve(el);
            return;
        }
        el.addEventListener('error', reject);
        el.addEventListener('abort', reject);
        el.addEventListener('load', function loadScriptHandler() {
            el.setAttribute('data-loaded', true);
            resolve(el);
        });
        if (shouldAppend) document.head.appendChild(el);
    })
}


export default {
    loadCrisp:loadCrisp,
    loadStripe:loadStripe,
    loadPaypal:loadPaypal,
    loadStream:loadStream
}
import axios from "axios";


function getProductsByCategoryId(categoryId){
    return axios.get(process.env.VUE_APP_API_BASE_URL+`/products`,
        {
            params:{
                categoryId:categoryId
            }
        })
}


function getProductsHome(){
    return axios.get(process.env.VUE_APP_API_BASE_URL+`/products/home`,
        {
        })
}


function createAnonymousUser(){
    return axios.post(process.env.VUE_APP_API_BASE_URL+"/user/anonymous")
}

function addToCart(productId, delta, idToken){
    return axios.put(process.env.VUE_APP_API_BASE_URL+"/me/cart",{
        productId : productId,
        delta: delta
    },{
        headers : {
            'Authorization': `Bearer ${idToken}`
        }
    })
}

function checkoutv2(idToken, data){
    return axios.post(process.env.VUE_APP_API_BASE_URL+"/checkout2",data,{
        headers : {
            'Authorization': `Bearer ${idToken}`
        }
    })
}

function getCart(idToken){
    return axios.get(process.env.VUE_APP_API_BASE_URL+"/me/cart",{
        headers : {
            'Authorization': `Bearer ${idToken}`
        }
    })
}

function updateCartAddress(idToken,address){
    return axios.put(process.env.VUE_APP_API_BASE_URL+"/me/cart/address",address,{
        headers : {
            'Authorization': `Bearer ${idToken}`
        }
    })
}

function tracking(data, idToken = ""){
    if (localStorage.getItem("userStatus") === "admin"){
        data.d = true
    }
    return axios.post(process.env.VUE_APP_API_BASE_URL+"/view",data,{
        headers : {
            'Authorization': `Bearer ${idToken}`
        }
    })
}

// /restaurantInfo


function getRestaurantInfo(){
    return axios.get(process.env.VUE_APP_API_BASE_URL+"/restaurantInfo")
}
export default {
    getProductsByCategoryId: getProductsByCategoryId,
    getProductsHome:getProductsHome,
    createAnonymousUser:createAnonymousUser,
    addToCart:addToCart,
    checkoutv2:checkoutv2,
    getCart:getCart,
    updateCartAddress:updateCartAddress,
    tracking:tracking,
    getRestaurantInfo:getRestaurantInfo
}
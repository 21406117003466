<template>
  <div>
    <NavBar></NavBar>
    <div class="container">
      <ProgressBar :value="valueProgress"></ProgressBar>
      <h1>Récapitulatif</h1>
      <DefaultLoader v-if="loading"></DefaultLoader>
      <div class="mt-2" v-else>
        <CartComponent
            :cart="this.cart"
            button-text="Payer"
            :show-shipping-address="true"
            :trigger-payment-on-click="true"
            checkout-mode="TO_DELIVER"

        ></CartComponent>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar";
import ProgressBar from "@/components/ProgressBar";
import CartComponent from "@/components/CartComponent";
import AuthHelper from "@/authHelper";
import Webservice from "@/webservice";
import DefaultLoader from "@/components/DefaultLoader";
import {useHead} from "@vueuse/head";
import Consts from "@/consts";

export default {
  name: "FunnelLivraisonStepRecap",
  data(){
    return {
      valueProgress: 60,
      cart:null,
      loading: false,
    }
  },
  created() {
    this.fetchData()
  },
  setup() {
    useHead({
      // Can be static or computed
      title: "Sayuri Montrouge Site Officiel - Commande en ligne",
      meta: [
        {
          name: "description",
          content: Consts.STANDARD_DESCRIPTION,
        },
      ],
    })
  },
  mounted() {
    // catch event cart-update to launch an update of cart data
    this.emitter.on("add-to-cart", e => {
      console.log(e,"add to cart received")
      this.fetchData()
    });
  },
  components: {DefaultLoader, CartComponent, ProgressBar, NavBar},
  methods: {
    fetchData(){
      this.loading = true
      AuthHelper.getAnonUserOrCreateOneIfExpired().then((user)=> {
        //console.log("get cart from HomeView")
        Webservice.getCart(user.idToken).then((res)=>{
          this.cart = res.data
          this.loading = false
        }).catch((err)=>{
          // when we have 403 we force anon user recreation
          if (err.response.status === 403){
            // refresh the user
            AuthHelper.createAnonUser().then(()=>{
              // relaunch treatment
              this.fetchData()
            })
          }
          this.loading = false
        })
      }).catch(()=>{
        this.loading = false
      })
    }
  },
}
</script>

<style scoped>

</style>
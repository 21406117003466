<template>
  <div class="mt-2 text-center">
    <img style="width: 350px;" :src="require('@/assets/paiement secure.jpeg')" alt="Your Image Description">

  </div>
  <div class="mt-2">
    <p class="text-center"><i class="bi bi-bag-check"></i> Livraison en 30-40 min</p>
  </div>
  <div class="mt-2">
    <p class="text-center"><i class="bi bi-people"></i> Soutenez votre restaurant</p>
  </div>




  <div class="row">
    <div class="text-center mb-2 col">
      <h5>Vous avez besoin d'aide ?</h5>
      <p>Un Doute ? Vous voulez parler à l'équipe du restaurant ?</p>
      <a href="tel:+331-42-53-48-19" @click="onPhoneClick" class="resto-phone-number" >
        <button class="btn btn-primary mb-2"><i class="bi bi-telephone"></i> 01 42 53 48 19</button>
      </a>
    </div>
  </div>
</template>

<script>
import Webservice from "@/webservice";

export default {
  name: "CartReassurance",
  methods:{
    onPhoneClick(){
      Webservice.tracking({
        action:{
          referrer:document.referrer,
          lang: navigator.language || navigator.userLanguage,
          name:"CLICK_PHONE_CART_REASSURANCE",
          utm: {
            source: this.$route.query.utm_source,
            medium: this.$route.query.utm_medium,
            campaign: this.$route.query.utm_campaign,
            term: this.$route.query.utm_term,
            content: this.$route.query.utm_content,
          }
        }
      })
    },
  }
}
</script>

<style scoped>

</style>
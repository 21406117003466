<template>
  <div>
    <NavBar></NavBar>
    <div class="container mt-3">

      <div class="bg-light p-5 rounded mb-2">
        <h1>Le restaurant</h1>
        <p class="lead">Notre restaurant familial vous accueille tous les jours sur place</p>
        <p class="lead">Appelez-nous pour faire une réservation:</p>
        <a href="tel:+331-42-53-48-19" @click="onPhoneClick" class="resto-phone-number">
          <button class="btn btn-primary mb-2 btn-lg"><i class="bi bi-telephone"></i> 01 42 53 48 19</button>
        </a>
      </div>
      <h2>Horraires d'ouvertures</h2>
      <div class="row">
        <div class="col-4">
          <table class="table">
            <tbody>
              <tr>
                <th scope="row">Lundi</th>
                <td>11:30–14:30</td>
                <td>18:30–22:30</td>
              </tr>
              <tr>
                <th scope="row">Mardi</th>
                <td>11:30–14:30</td>
                <td>18:30–22:30</td>
              </tr>
              <tr>
                <th scope="row">Mercredi</th>
                <td>11:30–14:30</td>
                <td>18:30–22:30</td>
              </tr>
              <tr>
                <th scope="row">Jeudi</th>
                <td>11:30–14:30</td>
                <td>18:30–22:30</td>
              </tr>
              <tr>
                <th scope="row">Vendredi</th>
                <td>11:30–14:30</td>
                <td>18:30–22:30</td>
              </tr>
              <tr>
                <th scope="row">Samedi</th>
                <td>Fermé</td>
                <td>18:30–22:30</td>
              </tr>
              <tr>
                <th scope="row">Dimanche</th>
                <td>Fermé</td>
                <td>18:30–22:30</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>


      <DefaultFooter></DefaultFooter>
    </div>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar";
import DefaultFooter from "@/components/DefaultFooter";
import {useHead} from "@vueuse/head";
import Consts from "@/consts";
import Webservice from "@/webservice";


export default {
  name: "RestoView",
  setup() {
    useHead({
      // Can be static or computed
      title: "Sayuri Montrouge Site Officiel - Horraires",
      meta: [
        {
          name: "description",
          content: Consts.STANDARD_DESCRIPTION,
        },
      ],
    })
  },
  components: {DefaultFooter, NavBar},
  methods:{
    onPhoneClick(){
      Webservice.tracking({
        action:{
          referrer:document.referrer,
          lang: navigator.language || navigator.userLanguage,
          name:"CLICK_PHONE_RESTO_VIEW",
          utm: {
            source: this.$route.query.utm_source,
            medium: this.$route.query.utm_medium,
            campaign: this.$route.query.utm_campaign,
            term: this.$route.query.utm_term,
            content: this.$route.query.utm_content,
          }
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <div>
    <div class="container" v-if="cart">
        <div class="text-center mb-5 mt-5" v-if="cart.items === null || cart.items.length === 0">
          <p>Votre Panier est vide</p>
          <p>Jetez un coup d'oeil à notre menu ! </p>
          <!--
          <router-link  :to="{ name: routeNames.HOME}">
            <button type="button" class="btn btn-info">Menu</button>
          </router-link>-->
        </div>
        <div v-else>
          <div class="container m-1 pb-1">
            <!--- ITEMS -->
            <CartView :items="cart.items" :updatable="true" ></CartView>
          </div>
          <!--- END ITEMS -->
          <!--- Basket Footer -->
          <div class="container">
            <div class="mt-3 row">
              <div class="col">
                Sous-Total:
              </div>
              <div class="text-right col" >
                <strong>{{cart.totalPriceVATInc.display}}</strong>
              </div>
            </div>
            <hr>
            <div class="mt-2 row">
              <div class="col">
                Livraison:
              </div>
              <div  class="text-right col">
                <p>Gratuite !</p>
              </div>
            </div>
            <div class="mt-2 mb-2 row" v-if="showShippingAddress">
              <div class="col">
                <strong>Addresse de livraison:</strong><br>
                {{ cart.shippingAddress.name }}<br>
                {{ cart.shippingAddress.line1 }}<br>
                {{ cart.shippingAddress.postalCode }} {{ cart.shippingAddress.locality }}<br>
                <i class="bi bi-telephone"></i> {{cart.shippingAddress.phoneNumberParsed.formatNational}}
              </div>
            </div>
            <CartReassurance></CartReassurance>
            <small> <i class="bi bi-percent"></i> Un code promo ? Vous pourrez le saisir au moment du paiement</small>
          </div>
          <!--- End  Basket Footer -->
        </div>
        <div class="alert alert-danger mt-3" role="alert" v-if="showErrorAlert">
          {{ errorMessage }}
        </div>
      </div>
    </div>
</template>

<script>

import CartView from "@/components/CartView";
import Consts from "@/consts";
import CartReassurance from "@/components/CartReassurance";

export default {
  name: "CartComponentMobile",
  components: {CartReassurance, CartView},
  props:{
    cart: Object,
    showShippingAddress: Boolean,
    triggerPaymentOnClick: Boolean,
    checkoutMode:String,
  },
  data(){
    return {
      disableButton:false,
      shippingCountryCode: "",
      showSpinnerStripe: false,
      errorMessage: "",
      showErrorAlert : false,
      loading: false,
      emptyCart: false,
      routeNames : Consts.ROUTE_NAMES,
      currency:"EUR",
      tutorialId: process.env.VUE_APP_MAIN_TUTORIAL_ID,
    }
  },
  methods: {
    openCrisp() {
      window.$crisp.push(['do', 'chat:open'])
    },
  },
}
</script>

<style scoped>

</style>
<template>
  <div class="bap-title">
    <h3> Conditions générales de Ventes</h3>
    <p>En vigueur au 17/05/2022</p>
    <h5>ARTICLE 1 - Champ d&#39;application</h5>
    <p class="c1"><span class="c9">Les pr&eacute;sentes Conditions G&eacute;n&eacute;rales de Vente (dites &laquo; CGV &raquo;) s&#39;appliquent, sans restriction ni r&eacute;serve &agrave; l&#39;ensemble des ventes conclues par le Vendeur aupr&egrave;s d&#39;acheteurs non professionnels (&laquo; Les Clients ou le Client &raquo;), d&eacute;sirant acqu&eacute;rir les produits propos&eacute;s &agrave; la vente (&laquo; Les Produits &raquo;) par le Vendeur sur le site </span><span
        class="c9 c10">https://www.sayuri-montrouge.fr/</span><span class="c3">. Les Produits propos&eacute;s &agrave; la vente sur le site sont les suivants :</span>
    </p>
    <ul class="c16 lst-kix_c7oh3vbw3lz6-0 start">
      <li class="c0"><span class="c3">Restauration</span></li>
    </ul>
    <p class="c1"><span class="c3">Les caract&eacute;ristiques principales des Produits et notamment les sp&eacute;cifications, illustrations et indications de dimensions ou de capacit&eacute; des Produits, sont pr&eacute;sent&eacute;es sur le site https://www.sayuri-montrouge.fr/ ce dont le client est tenu de prendre connaissance avant de commander.</span>
    </p>
    <p class="c1"><span class="c3">Le choix et l&#39;achat d&#39;un Produit sont de la seule responsabilit&eacute; du Client.</span>
    </p>
    <p class="c1"><span class="c3">Les offres de Produits s&#39;entendent dans la limite des stocks disponibles, tels que pr&eacute;cis&eacute;s lors de la passation de la commande.</span>
    </p>
    <p class="c1 c5"><span class="c3"></span></p>
    <p class="c1"><span class="c9">Ces CGV sont accessibles &agrave; tout moment sur le site </span><span class="c9 c10">https://www.sayuri-montrouge.fr/</span><span
        class="c3">&nbsp;et pr&eacute;vaudront sur toute autre document.</span></p>
    <p class="c1"><span class="c9">Le Client d&eacute;clare avoir pris connaissance des pr&eacute;sentes CGV et les avoir accept&eacute;es en cochant la case pr&eacute;vue &agrave; cet effet avant la mise en &oelig;uvre de la proc&eacute;dure de commande en ligne du site </span><span
        class="c9 c10">https://www.sayuri-montrouge.fr/</span><span class="c3">.</span></p>
    <p class="c1"><span class="c3">Sauf preuve contraire, les donn&eacute;es enregistr&eacute;es dans le syst&egrave;me informatique du Vendeur constituent la preuve de l&#39;ensemble des transactions conclues avec le Client.</span>
    </p>
    <p class="c1 c5"><span class="c3"></span></p>
    <p class="c1 c13"><span class="c3">Les coordonn&eacute;es du Vendeur sont les suivantes :</span></p>
    <p class="c1 c13"><span class="c3">FUJI TOP (SAYURI), SARL</span></p>
    <p class="c1 c13"><span class="c3">Capital social de 8000 euros</span></p>
    <p class="c1 c13"><span class="c3">Immatricul&eacute; au RCS de Nanterre, sous le num&eacute;ro B 802798405</span></p>
    <p class="c1 c13"><span class="c3">115 AV ARISTIDE BRIAND À MONTROUGE</span></p>
    <p class="c1 c13"><span class="c3">t&eacute;l&eacute;Téléphone : 01 42 53 48 19</span></p>
    <p class="c1 c5"><span class="c3"></span></p>
    <p class="c1 c13"><span class="c3">Num&eacute;ro de TVA Intracommunautaire FR75802798405</span></p>
    <p class="c1 c5"><span class="c3"></span></p>
    <p class="c1"><span class="c3">Les Produits pr&eacute;sent&eacute;s sur le site https://www.sayuri-montrouge.fr/ sont propos&eacute;s &agrave; la vente pour les territoires suivants : France Métropolitaine</span>
    </p>
    <p class="c1 c5"><span class="c3"></span></p>
    <p class="c5 c7"><span class="c3"></span></p>
    <h5>ARTICLE 2 - Prix</h5>
    <p class="c1"><span class="c9">Les Produits sont fournis aux tarifs en vigueur figurant sur le site </span><span
        class="c9 c10">https://www.sayuri-montrouge.fr/</span><span class="c3">, lors de l&#39;enregistrement de la commande par le Vendeur.</span>
    </p>
    <p class="c1"><span class="c3">Les prix sont exprim&eacute;s en Euros, HT et TTC.</span></p>
    <p class="c1"><span class="c9">Les tarifs tiennent compte d&#39;&eacute;ventuelles r&eacute;ductions qui seraient consenties par le Vendeur sur le site </span><span
        class="c9 c10">https://www.sayuri-montrouge.fr/</span><span class="c3">.</span></p>
    <p class="c1"><span class="c3">Ces tarifs sont fermes et non r&eacute;visables pendant leur p&eacute;riode de validit&eacute; mais le Vendeur se r&eacute;serve le droit, hors p&eacute;riode de validit&eacute;, d&rsquo;en modifier les prix &agrave; tout moment.</span>
    </p>
    <p class="c1"><span class="c3">Les prix ne comprennent pas les frais de traitement, d&#39;exp&eacute;dition, de transport et de livraison, qui sont factur&eacute;s en suppl&eacute;ment, dans les conditions indiqu&eacute;es sur le site et calcul&eacute;s pr&eacute;alablement &agrave; la passation de la commande.</span>
    </p>
    <p class="c1"><span class="c3">Le paiement demand&eacute; au Client correspond au montant total de l&#39;achat, y compris ces frais.</span>
    </p>
    <p class="c1"><span class="c3">Une facture est &eacute;tablie par le Vendeur et remise au Client lors de la livraison des Produits command&eacute;s.</span>
    </p>
    <p class="c7 c5"><span class="c3"></span></p>
    <h5>ARTICLE 3 &ndash; Commandes</h5>
    <p class="c1"><span class="c9">Il appartient au Client de s&eacute;lectionner sur le site </span><span class="c9 c10">https://www.sayuri-montrouge.fr/</span><span
        class="c3">&nbsp;les Produits qu&#39;il d&eacute;sire commander, selon les modalit&eacute;s suivantes :</span>
    </p>
    <p class="c1"><span class="c3">Le Client choisi un Produit qu&rsquo;il met dans son panier, Produit qu&rsquo;il pourra supprimer ou modifier avant de valider sa commande et d&rsquo;accepter les pr&eacute;sentes conditions g&eacute;n&eacute;rales de vente. Il rentrera ensuite ses coordonn&eacute;es ou se connectera &agrave; son espace et choisira le mode de livraison. Apr&egrave;s validation des informations, la commande sera consid&eacute;r&eacute;e comme d&eacute;finitive et exigera paiement de la part du Client selon les modalit&eacute;s pr&eacute;vues..</span>
    </p>
    <p class="c1"><span class="c3">Les offres de Produits sont valables tant qu&#39;elles sont visibles sur le site, dans la limite des stocks disponibles.</span>
    </p>
    <p class="c1"><span class="c3">La vente ne sera consid&eacute;r&eacute;e comme valide qu&rsquo;apr&egrave;s paiement int&eacute;gral du prix. Il appartient au Client de v&eacute;rifier l&#39;exactitude de la commande et de signaler imm&eacute;diatement toute erreur.</span>
    </p>
    <p class="c1"><span class="c9">Toute commande pass&eacute;e sur le site </span><span class="c9 c10">https://www.sayuri-montrouge.fr/ </span><span
        class="c3">constitue la formation d&#39;un contrat conclu &agrave; distance entre le Client et le Vendeur.</span>
    </p>
    <p class="c1"><span class="c3">Le Vendeur se r&eacute;serve le droit d&#39;annuler ou de refuser toute commande d&#39;un Client avec lequel il existerait un litige relatif au paiement d&#39;une commande ant&eacute;rieure.</span>
    </p>
    <p class="c1"><span class="c3">Le Client pourra suivre l&#39;&eacute;volution de sa commande sur le site.</span></p>
    <p class="c7 c5"><span class="c3"></span></p>
    <h5>ARTICLE 4 - Conditions de paiement</h5>
    <p class="c1"><span class="c3">Le prix est pay&eacute; par voie de paiement s&eacute;curis&eacute;, selon les modalit&eacute;s suivantes :</span>
    </p>
    <p class="c1 c5"><span class="c3"></span></p>
    <ul class="c16 lst-kix_m92u2bwmgych-0 start">
      <li class="c0"><span class="c3">paiement par carte bancaire</span></li>
    </ul>
    <p class="c1 c5"><span class="c3"></span></p>
    <p class="c1"><span class="c3">Le prix est payable comptant par le Client, en totalit&eacute; au jour de la pasation de la commande.</span>
    </p>
    <p class="c1 c5"><span class="c3"></span></p>
    <p class="c1 c5"><span class="c3"></span></p>
    <p class="c1"><span class="c3">Les donn&eacute;es de paiement sont &eacute;chang&eacute;es en mode crypt&eacute; gr&acirc;ce au protocole d&eacute;fini par le prestataire de paiement agr&eacute;&eacute; intervenant pour les transactions bancaires r&eacute;alis&eacute;e sur le site https://www.sayuri-montrouge.fr/.</span>
    </p>
    <p class="c1 c5"><span class="c3"></span></p>
    <p class="c1 c5"><span class="c3"></span></p>
    <p class="c1"><span class="c3">Les paiements effectu&eacute;s par le Client ne seront consid&eacute;r&eacute;s comme d&eacute;finitifs qu&#39;apr&egrave;s encaissement effectif par le Vendeur des sommes dues.</span>
    </p>
    <p class="c1"><span class="c3">Le Vendeur ne sera pas tenu de proc&eacute;der &agrave; la d&eacute;livrance des Produits command&eacute;s par le Client si celui-ci ne lui en paye pas le prix en totalit&eacute; dans les conditions ci-dessus indiqu&eacute;es.</span>
    </p>

    <p class="c7 c5"><span class="c3"></span></p>
    <h5>ARTICLE 5 - Livraisons</h5>
    <p class="c1 c5"><span class="c3"></span></p>
    <p class="c1"><span class="c3">Les Produits command&eacute;s par le Client seront livr&eacute;s en France m&eacute;tropolitaine</span>
    </p>
    <p class="c1 c5"><span class="c3"></span></p>
    <p class="c1"><span class="c3">Les livraisons interviennent dans un d&eacute;lai de 1 jour maximum. Les d&eacute;lais de livraison (apr&egrave;s fabrication de la commande et envoi au Transporteur) sont en g&eacute;n&eacute;ral de 30 à 45 minutes suivant la commande lorsque le restaurant est ouvert. Ce d&eacute;lai est susceptible d&#39;&ecirc;tre rallong&eacute; en fonction du Transporteur. &agrave; l&#39;adresse indiqu&eacute;e par le Client lors de sa commande sur le site.</span>
    </p>
    <p class="c1"><span class="c3">La livraison est constitu&eacute;e par le transfert au Client de la possession physique ou du contr&ocirc;le du Produit. Sauf cas particulier ou indisponibilit&eacute; d&#39;un ou plusieurs Produits, les Produits command&eacute;s seront livr&eacute;s en une seule fois.</span>
    </p>
    <p class="c1"><span class="c3">Le Vendeur s&#39;engage &agrave; faire ses meilleurs efforts pour livrer les produits command&eacute;s par le Client dans les d&eacute;lais ci-dessus pr&eacute;cis&eacute;s.</span>
    </p>
    <p class="c1 c5"><span class="c3"></span></p>
    <p class="c1"><span class="c3">Si les Produits command&eacute;s n&#39;ont pas &eacute;t&eacute; livr&eacute;s dans un d&eacute;lai de 10 jours apr&egrave;s la date indicative de livraison, pour toute autre cause que la force majeure ou le fait du Client, la vente pourra &ecirc;tre r&eacute;solue &agrave; la demande &eacute;crite du Client dans les conditions pr&eacute;vues aux articles L 216-2, L 216-3 et L241-4 du Code de la consommation. Les sommes vers&eacute;es par le Client lui seront alors restitu&eacute;es au plus tard dans les quatorze jours qui suivent la date de d&eacute;nonciation du contrat, &agrave; l&#39;exclusion de toute indemnisation ou retenue.</span>
    </p>
    <p class="c1 c5"><span class="c3"></span></p>
    <p class="c1"><span class="c3">Les livraisons sont assur&eacute;es par un transporteur ind&eacute;pendant, &agrave; l&#39;adresse mentionn&eacute;e par le Client lors de la commande et &agrave; laquelle le transporteur pourra facilement acc&eacute;der.</span>
    </p>
    <p class="c1"><span class="c3">Lorsque le Client s&#39;est lui-m&ecirc;me charg&eacute; de faire appel &agrave; un transporteur qu&#39;il choisit lui-m&ecirc;me, la livraison est r&eacute;put&eacute;e effectu&eacute;e d&egrave;s la remise des Produits command&eacute;s par le Vendeur au transporteur qui les a accept&eacute;s sans r&eacute;serves. Le Client reconna&icirc;t donc que c&#39;est au transporteur qu&#39;il appartient d&#39;effectuer la livraison et ne dispose d&#39;aucun recours en garantie contre le Vendeur en cas de d&eacute;faut de livraison des marchandises transport&eacute;es.</span>
    </p>
    <p class="c1 c5"><span class="c3"></span></p>
    <p class="c1"><span class="c3">En cas de demande particuli&egrave;re du Client concernant les conditions d&#39;emballage ou de transport des produits command&eacute;s, d&ucirc;ment accept&eacute;es par &eacute;crit par le Vendeur, les co&ucirc;ts y li&eacute;s feront l&#39;objet d&#39;une facturation sp&eacute;cifique compl&eacute;mentaire, sur devis pr&eacute;alablement accept&eacute; par &eacute;crit par le Client.</span>
    </p>
    <p class="c1"><span class="c9">Le Client est tenu de v&eacute;rifier l&#39;&eacute;tat des produits livr&eacute;s. Il dispose d&#39;un d&eacute;lai de </span><span
        class="c9 c10">30 jours &agrave; compter de la r&eacute;ception du colis.</span><span class="c9">&nbsp;&agrave; compter de la livraison pour formuler des r&eacute;clamations par</span><span
        class="c9 c10">&nbsp;La r&eacute;clamation peut &ecirc;tre faite par courrier (&agrave; l&#39;adresse &quot;FUJI TOP SAYURI 115 AV ARISTIDE BRIAND À MONTROUGE; ou par email &agrave; l&#39;addresse suivante &nbsp;&quot;restaurant.sayuri.montrouge@gmail.com&quot;.</span><span
        class="c3">, accompagn&eacute;es de tous les justificatifs y aff&eacute;rents (photos notamment). Pass&eacute; ce d&eacute;lai et &agrave; d&eacute;faut d&#39;avoir respect&eacute; ces formalit&eacute;s, les Produits seront r&eacute;put&eacute;s conformes et exempts de tout vice apparent et aucune r&eacute;clamation ne pourra &ecirc;tre valablement accept&eacute;e par le Vendeur.</span>
    </p>
    <p class="c1"><span class="c3">Le Vendeur remboursera ou remplacera dans les plus brefs d&eacute;lais et &agrave; ses frais, les Produits livr&eacute;s dont les d&eacute;fauts de conformit&eacute; ou les vices apparents ou cach&eacute;s auront &eacute;t&eacute; d&ucirc;ment prouv&eacute;s par le Client, dans les conditions pr&eacute;vues aux articles L 217-4 et suivants du Code de la consommation et celles pr&eacute;vues aux pr&eacute;sentes CGV.</span>
    </p>
    <p class="c1 c5"><span class="c3"></span></p>
    <p class="c7"><span class="c3">Le transfert des risques de perte et de d&eacute;t&eacute;rioration s&#39;y rapportant, ne sera r&eacute;alis&eacute; qu&#39;au moment o&ugrave; le Client prendra physiquement possession des Produits. Les Produits voyagent donc aux risques et p&eacute;rils du Vendeur sauf lorsque le Client aura lui-m&ecirc;me choisi le transporteur. A ce titre, les risques sont transf&eacute;r&eacute;s au moment de la remise du bien au transporteur.</span>
    </p>
    <h5>ARTICLE 6 - Transfert de propri&eacute;t&eacute;</h5>
    <p class="c1 c5"><span class="c3"></span></p>
    <p class="c1"><span class="c3">Le transfert de propri&eacute;t&eacute; des Produits du Vendeur au Client ne sera r&eacute;alis&eacute; qu&#39;apr&egrave;s complet paiement du prix par ce dernier, et ce quelle que soit la date de livraison desdits Produits.</span>
    </p>
    <p class="c7 c5"><span class="c3"></span></p>
    <h5>ARTICLE 7 - Droit de r&eacute;tractation</h5>
    <p class="c1 c5"><span class="c3"></span></p>
    <p class="c1"><span
        class="c9">Selon les modalit&eacute;s de l&rsquo;article L221-18 du Code de la Consommation &laquo;</span><span
        class="c11 c9 c10">Le consommateur dispose d&#39;un d&eacute;lai de quatorze jours pour exercer son droit de r&eacute;tractation d&#39;un contrat conclu &agrave; distance, &agrave; la suite d&#39;un d&eacute;marchage t&eacute;l&eacute;phonique ou hors &eacute;tablissement, sans avoir &agrave; motiver sa d&eacute;cision ni &agrave; supporter d&#39;autres co&ucirc;ts que ceux pr&eacute;vus aux articles L. 221-23 &agrave; L. 221-25.</span>
    </p>
    <p class="c1 c5"><span class="c3"></span></p>
    <p class="c1"><span class="c11 c9 c10">Le d&eacute;lai mentionn&eacute; au premier alin&eacute;a court &agrave; compter du jour :</span>
    </p>
    <p class="c1 c5"><span class="c3"></span></p>
    <p class="c1"><span class="c11 c9 c10">1&deg; De la conclusion du contrat, pour les contrats de prestation de services et ceux mentionn&eacute;s &agrave; l&#39;article L. 221-4 ;</span>
    </p>
    <p class="c1 c5"><span class="c3"></span></p>
    <p class="c1"><span class="c11 c9 c10">2&deg; De la r&eacute;ception du bien par le consommateur ou un tiers, autre que le transporteur, d&eacute;sign&eacute; par lui, pour les contrats de vente de biens. Pour les contrats conclus hors &eacute;tablissement, le consommateur peut exercer son droit de r&eacute;tractation &agrave; compter de la conclusion du contrat.</span>
    </p>
    <p class="c1 c5"><span class="c3"></span></p>
    <p class="c1"><span class="c11 c9 c10">Dans le cas d&#39;une commande portant sur plusieurs biens livr&eacute;s s&eacute;par&eacute;ment ou dans le cas d&#39;une commande d&#39;un bien compos&eacute; de lots ou de pi&egrave;ces multiples dont la livraison est &eacute;chelonn&eacute;e sur une p&eacute;riode d&eacute;finie, le d&eacute;lai court &agrave; compter de la r&eacute;ception du dernier bien ou lot ou de la derni&egrave;re pi&egrave;ce.</span>
    </p>
    <p class="c1 c5"><span class="c3"></span></p>
    <p class="c1"><span class="c9 c10">Pour les contrats pr&eacute;voyant la livraison r&eacute;guli&egrave;re de biens pendant une p&eacute;riode d&eacute;finie, le d&eacute;lai court &agrave; compter de la r&eacute;ception du premier bien. </span><span
        class="c3">&raquo;</span></p>
    <p class="c1"><span class="c3">Le droit de r&eacute;tractation peut &ecirc;tre exerc&eacute; en ligne, &agrave; l&#39;aide du formulaire de r&eacute;tractation ci-joint et &eacute;galement disponible sur le site ou de toute autre d&eacute;claration, d&eacute;nu&eacute;e d&#39;ambigu&iuml;t&eacute;, exprimant la volont&eacute; de se r&eacute;tracter et notamment par courrier postal adress&eacute; au Vendeur aux coordonn&eacute;es postales ou mail indiqu&eacute;es &agrave; l&rsquo;ARTICLE 1 des CGV.</span>
    </p>
    <p class="c1"><span class="c3">Les retours sont &agrave; effectuer dans leur &eacute;tat d&#39;origine et complets (emballage, accessoires, notice...) permettant leur recommercialisation &agrave; l&#39;&eacute;tat neuf, accompagn&eacute;s de la facture d&#39;achat.</span>
    </p>
    <p class="c1"><span class="c3">Les Produits endommag&eacute;s, salis ou incomplets ne sont pas repris.</span></p>
    <p class="c1"><span class="c3">Les frais de retour restant &agrave; la charge du Client.</span></p>
    <p class="c1"><span class="c3">L&#39;&eacute;change (sous r&eacute;serve de disponibilit&eacute;) ou le remboursement sera effectu&eacute; dans un d&eacute;lai de 14 jours &agrave; compter de la r&eacute;ception, par le Vendeur, des Produits retourn&eacute;s par le Client dans les conditions pr&eacute;vues au pr&eacute;sent article.</span>
    </p>
    <p class="c7 c5"><span class="c3"></span></p>
    <h5>ARTICLE 8 - Responsabilit&eacute; du Vendeur - Garanties</h5>
    <p class="c1"><span class="c3">Les Produits fournis par le Vendeur b&eacute;n&eacute;ficient :</span></p>
    <ul class="c16 lst-kix_mayemuq5b2b1-0 start">
      <li class="c0"><span class="c3">de la garantie l&eacute;gale de conformit&eacute;, pour les Produits d&eacute;fectueux, ab&icirc;m&eacute;s ou endommag&eacute;s ou ne correspondant pas &agrave; la commande,</span>
      </li>
      <li class="c0"><span class="c3">de la garantie l&eacute;gale contre les vices cach&eacute;s provenant d&#39;un d&eacute;faut de mati&egrave;re, de conception ou de fabrication affectant les produits livr&eacute;s et les rendant impropres &agrave; l&#39;utilisation,</span>
      </li>
    </ul>
    <p class="c1"><span class="c4">Dispositions relatives aux garanties l&eacute;gales</span></p>
    <p class="c1"><span class="c3">Article L217-4 du Code de la consommation</span></p>
    <p class="c1"><span class="c11 c9 c10">&laquo; Le vendeur est tenu de livrer un bien conforme au contrat et r&eacute;pond des d&eacute;fauts de conformit&eacute; existant lors de la d&eacute;livrance. Il r&eacute;pond &eacute;galement des d&eacute;fauts de conformit&eacute; r&eacute;sultant de l&#39;emballage, des instructions de montage ou de l&#39;installation lorsque celle-ci a &eacute;t&eacute; mise &agrave; sa charge par le contrat ou a &eacute;t&eacute; r&eacute;alis&eacute;e sous sa responsabilit&eacute;. &raquo;</span>
    </p>
    <p class="c1"><span class="c3">Article L217-5 du Code de la consommation</span></p>
    <p class="c1"><span class="c11 c9 c10">&laquo; Le bien est conforme au contrat :</span></p>
    <p class="c1"><span class="c11 c9 c10">1&deg; S&#39;il est propre &agrave; l&#39;usage habituellement attendu d&#39;un bien semblable et, le cas &eacute;ch&eacute;ant :</span>
    </p>
    <p class="c1"><span class="c11 c9 c10">- s&#39;il correspond &agrave; la description donn&eacute;e par le vendeur et poss&egrave;de les qualit&eacute;s que celui-ci a pr&eacute;sent&eacute;es &agrave; l&#39;acheteur sous forme d&#39;&eacute;chantillon ou de mod&egrave;le ;</span>
    </p>
    <p class="c1"><span class="c11 c9 c10">- s&#39;il pr&eacute;sente les qualit&eacute;s qu&#39;un acheteur peut l&eacute;gitimement attendre eu &eacute;gard aux d&eacute;clarations publiques faites par le vendeur, par le producteur ou par son repr&eacute;sentant, notamment dans la publicit&eacute; ou l&#39;&eacute;tiquetage ;</span>
    </p>
    <p class="c1"><span class="c11 c9 c10">2&deg; Ou s&#39;il pr&eacute;sente les caract&eacute;ristiques d&eacute;finies d&#39;un commun accord par les parties ou est propre &agrave; tout usage sp&eacute;cial recherch&eacute; par l&#39;acheteur, port&eacute; &agrave; la connaissance du vendeur et que ce dernier a accept&eacute;. &raquo;</span>
    </p>
    <p class="c1"><span class="c3">Article L217-12 du Code de la consommation</span></p>
    <p class="c1"><span class="c11 c9 c10">&laquo; L&#39;action r&eacute;sultant du d&eacute;faut de conformit&eacute; se prescrit par deux ans &agrave; compter de la d&eacute;livrance du bien. &raquo;</span>
    </p>
    <p class="c1"><span class="c3">Article 1641 du Code civil.</span></p>
    <p class="c1"><span class="c11 c9 c10">&laquo; Le vendeur est tenu de la garantie &agrave; raison des d&eacute;fauts cach&eacute;s de la chose vendue qui la rendent impropre &agrave; l&#39;usage auquel on la destine, ou qui diminuent tellement cet usage, que l&#39;acheteur ne l&#39;aurait pas acquise, ou n&#39;en aurait donn&eacute; qu&#39;un moindre prix, s&#39;il les avait connus. &raquo;</span>
    </p>
    <p class="c1"><span class="c3">Article 1648 alin&eacute;a 1er du Code civil</span></p>
    <p class="c1"><span class="c11 c9 c10">&laquo; L&#39;action r&eacute;sultant des vices r&eacute;dhibitoires doit &ecirc;tre intent&eacute;e par l&#39;acqu&eacute;reur dans un d&eacute;lai de deux ans &agrave; compter de la d&eacute;couverte du vice. &raquo;</span>
    </p>
    <p class="c1"><span class="c3">Article L217-16 du Code de la consommation.</span></p>
    <p class="c1"><span class="c9 c10 c11">&laquo; Lorsque l&#39;acheteur demande au vendeur, pendant le cours de la garantie commerciale qui lui a &eacute;t&eacute; consentie lors de l&#39;acquisition ou de la r&eacute;paration d&#39;un bien meuble, une remise en &eacute;tat couverte par la garantie, toute p&eacute;riode d&#39;immobilisation d&#39;au moins sept jours vient s&#39;ajouter &agrave; la dur&eacute;e de la garantie qui restait &agrave; courir. Cette p&eacute;riode court &agrave; compter de la demande d&#39;intervention de l&#39;acheteur ou de la mise &agrave; disposition pour r&eacute;paration du bien en cause, si cette mise &agrave; disposition est post&eacute;rieure &agrave; la demande d&#39;intervention. &raquo;</span>
    </p>
    <p class="c1"><span class="c3">Afin de faire valoir ses droits, le Client devra informer le Vendeur, par &eacute;crit (mail ou courrier), de la non-conformit&eacute; des Produits ou de l&#39;existence des vices cach&eacute;s &agrave; compter de leur d&eacute;couverte.</span>
    </p>
    <p class="c1"><span class="c3">Le Vendeur remboursera, remplacera ou fera r&eacute;parer les Produits ou pi&egrave;ces sous garantie jug&eacute;s non conformes ou d&eacute;fectueux.</span>
    </p>
    <p class="c1"><span class="c3">Les frais d&#39;envoi seront rembours&eacute;s sur la base du tarif factur&eacute; et les frais de retour seront rembours&eacute;s sur pr&eacute;sentation des justificatifs.</span>
    </p>
    <p class="c1"><span class="c3">Les remboursements, remplacements ou r&eacute;parations des Produits jug&eacute;s non conformes ou d&eacute;fectueux seront effectu&eacute;s dans les meilleurs d&eacute;lais et au plus tard dans les 30 jours jours suivant la constatation par le Vendeur du d&eacute;faut de conformit&eacute; ou du vice cach&eacute;. Ce remboursement pourra &ecirc;tre fait par virement ou ch&egrave;que bancaire.</span>
    </p>
    <p class="c1"><span class="c3">La responsabilit&eacute; du Vendeur ne saurait &ecirc;tre engag&eacute;e dans les cas suivants :</span>
    </p>
    <ul class="c16 lst-kix_prheij56znds-0 start">
      <li class="c0"><span class="c3">non respect de la l&eacute;gislation du pays dans lequel les produits sont livr&eacute;s, qu&#39;il appartient au Client de v&eacute;rifier,</span>
      </li>
      <li class="c0"><span class="c3">en cas de mauvaise utilisation, d&#39;utilisation &agrave; des fins professionnelles, n&eacute;gligence ou d&eacute;faut d&#39;entretien de la part du Client, comme en cas d&#39;usure normale du Produit, d&#39;accident ou de force majeure.</span>
      </li>
      <li class="c0"><span class="c3">Les photographies et graphismes pr&eacute;sent&eacute;s sur le site ne sont pas contractuels et ne sauraient engager la responsabilit&eacute; du Vendeur.</span>
      </li>
    </ul>
    <p class="c1"><span class="c3">La garantie du Vendeur est, en tout &eacute;tat de cause, limit&eacute;e au remplacement ou au remboursement des Produits non conformes ou affect&eacute;s d&#39;un vice.</span>
    </p>
    <p class="c7 c5"><span class="c3"></span></p>
    <h5>ARTICLE 9 - Donn&eacute;es personnelles</h5>
    <p class="c1 c5"><span class="c3"></span></p>
    <p class="c1"><span class="c3">Le Client est inform&eacute; que la collecte de ses donn&eacute;es &agrave; caract&egrave;re personnel est n&eacute;cessaire &agrave; la vente des Produits par le Vendeur ainsi qu&#39;&agrave; leur transmission &agrave; des tiers &agrave; des fins de livraison des Produits. Ces donn&eacute;es &agrave; caract&egrave;re personnel sont r&eacute;colt&eacute;es uniquement pour l&rsquo;ex&eacute;cution du contrat de vente.</span>
    </p>
    <p class="c1 c5"><span class="c3"></span></p>
    <p class="c1"><span class="c3">Le Client est par ailleurs inform&eacute; que la collecte de ses donn&eacute;es &agrave; caract&egrave;re personnel est &eacute;galement n&eacute;cessaire au vu des finalit&eacute;s suivantes :</span>
    </p>
    <p class="c1"><span class="c3">- Vos informations et donn&eacute;es personnelles sont utilis&eacute;es afin de g&eacute;rer votre commande sur notre site.</span>
    </p>
    <p class="c1"><span class="c3">- Vos informations et donn&eacute;es personnelles peuvent &ecirc;tre transmises &agrave; nos prestataires transporteurs afin d&#39;exp&eacute;dier votre commande.</span>
    </p>
    <p class="c1 c5"><span class="c3"></span></p>
    <p class="c1"><span class="c9 c15">9.1 Collecte des donn&eacute;es &agrave; caract&egrave;re personnel</span></p>
    <p class="c1"><span class="c3">Les donn&eacute;es &agrave; caract&egrave;re personnel qui sont collect&eacute;es sur le site https://www.sayuri-montrouge.fr/ sont les suivantes :</span>
    </p>
    <p class="c1"><span class="c15 c9">Commande de Produits :</span></p>
    <p class="c1"><span class="c3">Lors de la commande de Produits par le Client :</span></p>
    <p class="c1 c5"><span class="c3"></span></p>
    <p class="c1"><span class="c3">Noms, pr&eacute;noms, adresse postale, num&eacute;ro de t&eacute;l&eacute;phone et adresse e-mail.</span>
    </p>
    <p class="c1 c5"><span class="c3"></span></p>
    <p class="c1"><span class="c15 c9">Paiement</span></p>
    <p class="c1"><span class="c3">Dans le cadre du paiement des Produits propos&eacute;s sur le site https://www.sayuri-montrouge.fr/, celui-ci enregistre des donn&eacute;es financi&egrave;res relatives au compte bancaire ou &agrave; la carte de cr&eacute;dit du Client / utilisateur.</span>
    </p>
    <p class="c1 c5"><span class="c3"></span></p>
    <p class="c1"><span class="c15 c9">9.2 Destinataires des donn&eacute;es &agrave; caract&egrave;re personnel</span></p>
    <p class="c1 c5"><span class="c3"></span></p>
    <p class="c1"><span class="c3">Les donn&eacute;es &agrave; caract&egrave;re personnel sont utilis&eacute;es par le Vendeur et ses co-contractants pour l&rsquo;ex&eacute;cution du contrat et pour assurer l&rsquo;efficacit&eacute; de la prestation de vente et de d&eacute;livrance des Produits.</span>
    </p>
    <p class="c1"><span class="c3">La ou les cat&eacute;gorie(s) de co-contractant(s) est (sont) :</span></p>
    <p class="c1 c5"><span class="c3"></span></p>
    <ul class="c16 lst-kix_1q4dxqg7kyfw-0 start">
      <li class="c0"><span class="c3">Les prestataires de transport</span></li>
    </ul>
    <p class="c1 c5"><span class="c3"></span></p>
    <ul class="c16 lst-kix_jgr84qyxrima-0 start">
      <li class="c0"><span class="c3">Les prestataires &eacute;tablissements de paiement</span></li>
    </ul>
    <p class="c1 c5"><span class="c3"></span></p>
    <p class="c1"><span class="c15 c9">9.3 Responsable de traitement</span></p>
    <p class="c1 c5"><span class="c3"></span></p>
    <p class="c1"><span class="c3">Le responsable de traitement des donn&eacute;es est le Vendeur, au sens de la loi Informatique et libert&eacute;s et &agrave; compter du 25 mai 2018 du R&egrave;glement 2016/679 sur la protection des donn&eacute;es &agrave; caract&egrave;re personnel.</span>
    </p>
    <p class="c1 c5"><span class="c3"></span></p>
    <p class="c1"><span class="c15 c9">9.4 limitation du traitement</span></p>
    <p class="c1"><span class="c3">Sauf si le Client exprime son accord expr&egrave;s, ses donn&eacute;es &agrave; caract&egrave;re personnelles ne sont pas utilis&eacute;es &agrave; des fins publicitaires ou marketing.</span>
    </p>
    <p class="c1"><span class="c15 c9">9.5 Dur&eacute;e de conservation des donn&eacute;es</span></p>
    <p class="c1"><span class="c3">Le Vendeur conservera les donn&eacute;es ainsi recueillies pendant un d&eacute;lai de 5 ans, couvrant le temps de la prescription de la responsabilit&eacute; civile contractuelle applicable.</span>
    </p>
    <p class="c1"><span class="c15 c9">9.6 S&eacute;curit&eacute; et confidentialit&eacute;</span></p>
    <p class="c1"><span class="c3">Le Vendeur met en &oelig;uvre des mesures organisationnelles, techniques, logicielles et physiques en mati&egrave;re de s&eacute;curit&eacute; du num&eacute;rique pour prot&eacute;ger les donn&eacute;es personnelles contre les alt&eacute;rations, destructions et acc&egrave;s non autoris&eacute;s. Toutefois il est &agrave; signaler qu&rsquo;Internet n&rsquo;est pas un environnement compl&egrave;tement s&eacute;curis&eacute; et le Vendeur ne peut garantir la s&eacute;curit&eacute; de la transmission ou du stockage des informations sur Internet.</span>
    </p>
    <p class="c1"><span class="c15 c9">9.7 Mise en &oelig;uvre des droits des Clients et utilisateurs</span></p>
    <p class="c1"><span class="c3">En application de la r&egrave;glementation applicable aux donn&eacute;es &agrave; caract&egrave;re personnel, les Clients et utilisateurs du site https://www.sayuri-montrouge.fr/ disposent des droits suivants :</span>
    </p>
    <ul class="c16 lst-kix_wuv3wlhzav13-0 start">
      <li class="c0"><span class="c3">Ils peuvent mettre &agrave; jour ou supprimer les donn&eacute;es qui les concernent de la mani&egrave;re suivante :</span>
      </li>
    </ul>
    <p class="c1 c13"><span class="c3">Le client peut demander la suppression de ses donn&eacute;es personnelles soit en &eacute;crivant un email &agrave; l&#39;adresse &quot;restaurant.sayuri.montrouge@gmail.com&quot; ou par courier &agrave; l&#39;adresse &quot;FUJI TOP SAYURI, 115 AV ARISTIDE BRIAND À MONTROUGE.&quot;..</span>
    </p>
    <ul class="c16 lst-kix_8fxnbjgd5adm-0 start">
      <li class="c0"><span class="c3">Ils peuvent supprimer leur compte en &eacute;crivant &agrave; l&rsquo;adresse &eacute;lectronique indiqu&eacute; &agrave; l&rsquo;article 9.3 &laquo; Responsable de traitement &raquo;</span>
      </li>
      <li class="c0"><span class="c3">Ils peuvent exercer leur droit d&rsquo;acc&egrave;s pour conna&icirc;tre les donn&eacute;es personnelles les concernant en &eacute;crivant &agrave; l&rsquo;adresse indiqu&eacute; &agrave; l&rsquo;article 9.3 &laquo; Responsable de traitement &raquo;</span>
      </li>
      <li class="c0"><span class="c3">Si les donn&eacute;es &agrave; caract&egrave;re personnel d&eacute;tenues par le Vendeur sont inexactes, ils peuvent demander la mise &agrave; jour des informations des informations en &eacute;crivant &agrave; l&rsquo;adresse indiqu&eacute; &agrave; l&rsquo;article 9.3 &laquo; Responsable de traitement &raquo;</span>
      </li>
      <li class="c0"><span class="c3">Ils peuvent demander la suppression de leurs donn&eacute;es &agrave; caract&egrave;re personnel, conform&eacute;ment aux lois applicables en mati&egrave;re de protection des donn&eacute;es en &eacute;crivant &agrave; l&rsquo;adresse indiqu&eacute; &agrave; l&rsquo;article 9.3 &laquo; Responsable de traitement &raquo;</span>
      </li>
      <li class="c0"><span class="c3">Ils peuvent &eacute;galement solliciter la portabilit&eacute; des donn&eacute;es d&eacute;tenues par le Vendeur vers un autre prestataire</span>
      </li>
      <li class="c0"><span class="c3">Enfin, ils peuvent s&rsquo;opposer au traitement de leurs donn&eacute;es par le Vendeur</span>
      </li>
    </ul>
    <p class="c1"><span class="c3">Ces droits, d&egrave;s lors qu&rsquo;ils ne s&rsquo;opposent pas &agrave; la finalit&eacute; du traitement, peuvent &ecirc;tre exerc&eacute; en adressant une demande par courrier ou par E-mail au Responsable de traitement dont les coordonn&eacute;es sont indiqu&eacute;es ci-dessus.</span>
    </p>
    <p class="c1"><span class="c3">Le responsable de traitement doit apporter une r&eacute;ponse dans un d&eacute;lai maximum d&rsquo;un mois.</span>
    </p>
    <p class="c1"><span class="c3">En cas de refus de faire droit &agrave; la demande du Client, celui-ci doit &ecirc;tre motiv&eacute;.</span>
    </p>
    <p class="c1"><span class="c3">Le Client est inform&eacute; qu&rsquo;en cas de refus, il peut introduire une r&eacute;clamation aupr&egrave;s de la CNIL (3 place de Fontenoy, 75007 PARIS) ou saisir une autorit&eacute; judiciaire.</span>
    </p>
    <p class="c1"><span class="c3">Le Client peut &ecirc;tre invit&eacute; &agrave; cocher une case au titre de laquelle il accepte de recevoir des mails &agrave; caract&egrave;re informatifs et publicitaires de la part du Vendeur. Il aura toujours la possibilit&eacute; de retirer son accord &agrave; tout moment en contactant le Vendeur (coordonn&eacute;es ci-dessus) ou en suivant le lien de d&eacute;sabonnement.</span>
    </p>
    <p class="c7 c5"><span class="c3"></span></p>
    <h5>ARTICLE 10 - Propri&eacute;t&eacute; intellectuelle</h5>
    <p class="c1"><span class="c3">Le contenu du site https://www.sayuri-montrouge.fr/ est la propri&eacute;t&eacute; du Vendeur et de ses partenaires et est prot&eacute;g&eacute; par les lois fran&ccedil;aises et internationales relatives &agrave; la propri&eacute;t&eacute; intellectuelle.</span>
    </p>
    <p class="c7"><span class="c3">Toute reproduction totale ou partielle de ce contenu est strictement interdite et est susceptible de constituer un d&eacute;lit de contrefa&ccedil;on.</span>
    </p>
    <h5>ARTICLE 11 - Droit applicable - Langue</h5>
    <p class="c1"><span class="c3">Les pr&eacute;sentes CGV et les op&eacute;rations qui en d&eacute;coulent sont r&eacute;gies et soumises au droit fran&ccedil;ais.</span>
    </p>
    <p class="c1"><span class="c3">Les pr&eacute;sentes CGV sont r&eacute;dig&eacute;es en langue fran&ccedil;aise. Dans le cas o&ugrave; elles seraient traduites en une ou plusieurs langues &eacute;trang&egrave;res, seul le texte fran&ccedil;ais ferait foi en cas de litige.</span>
    </p>
    <p class="c7 c5"><span class="c3"></span></p>
    <h5>ARTICLE 12 - Litiges</h5>
    <p class="c1"><span class="c3">Pour toute r&eacute;clamation merci de contacter le service client&egrave;le &agrave; l&rsquo;adresse postale ou mail du Vendeur indiqu&eacute;e &agrave; l&rsquo;ARTICLE 1 des pr&eacute;sentes CGV.</span>
    </p>
    <p class="c1"><span class="c3">Le Client est inform&eacute; qu&#39;il peut en tout &eacute;tat de cause recourir &agrave; une m&eacute;diation conventionnelle, aupr&egrave;s des instances de m&eacute;diation sectorielles existantes ou &agrave; tout mode alternatif de r&egrave;glement des diff&eacute;rends (conciliation, par exemple) en cas de contestation.</span>
    </p>
    <!--
    <p class="c1"><span class="c3">En l&rsquo;esp&egrave;ce, le m&eacute;diateur d&eacute;sign&eacute; est</span></p>
    <p class="c1"><span class="c3">_______________</span></p>
    <p class="c1"><span class="c3">_______________</span></p>
    <p class="c1"><span class="c3">_______________</span></p>
    <p class="c1"><span class="c3">E-mail : _______________.</span></p>-->
    <p class="c1"><span class="c3">Le Client est &eacute;galement inform&eacute; qu&rsquo;il peut, &eacute;galement recourir &agrave; la plateforme de R&egrave;glement en Ligne des Litige (RLL) :https://webgate.ec.europa.eu/odr/main/index.cfm?event=main.home.show</span>
    </p>
    <p class="c1"><span class="c3">Tous les litiges auxquels les op&eacute;rations d&#39;achat et de vente conclues en application des pr&eacute;sentes CGV et qui n&rsquo;auraient pas fait l&rsquo;objet d&rsquo;un r&egrave;glement amiable entre le vendeur ou par m&eacute;diation, seront soumis aux tribunaux comp&eacute;tents dans les conditions de droit commun.</span>
    </p>
    <p class="c5 c12"><span class="c3"></span></p>
    <p class="c22"><span class="c8">ANNEXE I</span></p>
    <p class="c2"><span class="c10 c14">Formulaire de r&eacute;tractation</span></p>
    <p class="c1"><span class="c9">Date </span><span class="c11 c9 c10">______________________</span></p>
    <p class="c1"><span class="c3">Le pr&eacute;sent formulaire doit &ecirc;tre compl&eacute;t&eacute; et renvoy&eacute; uniquement si le Client souhaite se r&eacute;tracter de la commande pass&eacute;e sur https://www.sayuri-montrouge.fr/ sauf exclusions ou limites &agrave; l&#39;exercice du droit de r&eacute;tractation suivant les Conditions G&eacute;n&eacute;rales de Vente applicables.</span>
    </p>
    <p class="c1 c5"><span class="c3"></span></p>
    <p class="c1"><span class="c3">A l&#39;attention de SARL, FUJI TOP</span></p>
    <p class="c1"><span class="c3">115 AV ARISTIDE BRIAND À MONTROUGE.</span></p>
    <p class="c1 c5"><span class="c3"></span></p>
    <p class="c1"><span class="c3">Je notifie par la pr&eacute;sente la r&eacute;tractation du contrat portant sur le bien ci-dessous :</span>
    </p>
    <p class="c1"><span class="c9">- &nbsp;Commande du </span><span class="c11 c9 c10">(indiquer la date)</span></p>
    <p class="c1"><span class="c3">- &nbsp;Num&eacute;ro de la commande : ...........................................................</span>
    </p>
    <p class="c1"><span class="c3">- &nbsp;Nom du Client : ...........................................................................</span>
    </p>
    <p class="c1"><span class="c3">- &nbsp;Adresse du Client : .......................................................................</span>
    </p>
    <p class="c1"><span class="c9">Signature du Client </span><span class="c11 c9 c10">(uniquement en cas de notification du pr&eacute;sent formulaire sur papier)</span>
    </p>
    <p class="c5 c20"><span class="c3"></span></p>
    <p class="c5 c18"><span class="c11 c21"></span></p>

  </div>
</template>

<script>
export default {
name: "CGV"
}
</script>

<style scoped>

</style>
export default {
    STANDARD_DESCRIPTION:"Restaurant japonais familial qui propose des plats de qualité, extra-frais et servis avec amour",
    ROUTE_NAMES : {
        CART: "CART",
        HOME: "HOME",
        RESTO: "RESTO",
        CREDITS: "CREDITS",
        CGV: "CGV",
        LEGAL_MENTIONS: "LEGAL_MENTIONS",

        ORDER_OK: "ORDER_OK",
        FUNNEL_STEP_MODE: "FUNNEL_STEP_MODE",
        FUNNEL_MODE: "FUNNEL_MODE",
        FUNNEL_LIVRAISON_STEP_MENU: "FUNNEL_LIVRAISON_STEP_MENU",
        FUNNEL_LIVRAISON_STEP_ADDRESS: "FUNNEL_LIVRAISON_STEP_ADDRESS",
        FUNNEL_LIVRAISON_STEP_RECAP: "FUNNEL_LIVRAISON_STEP_RECAP",
        FUNNEL_A_EMPORTER_STEP_1: "FUNNEL_A_EMPORTER_STEP_1",

    }
}
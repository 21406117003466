<template>
  <div class="row mt-3 text-center">
    <div class="col">
      1. Addresse
    </div>
    <div class="col">
      2. Récapitulatif
    </div>
    <div class="col">
      3. Paiement
    </div>
  </div>
  <div class="progress mt-2">
    <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" :aria-valuenow="this.value" aria-valuemin="0" aria-valuemax="100" :style="'width: '+this.value+'%'"></div>
  </div>

</template>

<script>
export default {
  name: "ProgressBar",
  components: {},
  props:{
    value: Number
  }
}
</script>

<style scoped>

</style>